import React, { useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import successIcon from '../../../src/Assests/sideIcons/SuccessIcon.svg';
import failedIcon from '../../../src/Assests/sideIcons/failedIcon.svg';
import { RewardUser } from '../../Actions/ConfigsActions';
interface BulkPointsManagementModalProps {
  visible: boolean;
  onClose: () => void;
  confirm: boolean;
  setConfirm: (confirm: boolean) => void;
  amountAdded: { user_id: number; amount: number; is_cashback: boolean };
  setAmountAdded: (amountAdded: {
    user_id: number;
    amount: number;
    is_cashback: boolean;
  }) => void;
  select: string;
  setSelect: (select: string) => void;
}

const AwaitsCashBackPointsCustomerModal: React.FC<
  BulkPointsManagementModalProps
> = ({
  visible,
  onClose,
  confirm,
  setConfirm,
  amountAdded,
  setAmountAdded,
  select,
  setSelect,
}) => {
  const [check, setCheck] = useState(true);
  const [error, setError] = useState('');
  const handleConfirm = async () => {
    if (amountAdded.amount > 0) {
      try {
        const response = await RewardUser(amountAdded);
        if (
          response?.data.result?._text === 'true' ||
          response?.data.result === true
        ) {
          setCheck(true);
        } else {
          setCheck(false);
        }
      } catch (error) {
        setCheck(false);
      }
      setConfirm(true);
    } else {
      setError('Please enter amount');
    }
  };
  return (
    <>
      <Modal
        open={visible}
        onCancel={onClose}
        centered
        width={400}
        title={
          <h1 className=" text-lg">
            {select === 'pts' ? 'Award points' : 'Cashback'}
          </h1>
        }
        footer={null}
        styles={{ content: { borderRadius: '24px' } }}
      >
        <img
          src="src/Assests/sideIcons/awaitsCashbackBG.png"
          className="absolute top-0 left-0 z-[1]  "
          alt=""
        />
        {!confirm ? (
          <Form>
            <Form.Item className="bg-[#F9FAFB] p-1 rounded-xl border-[1px] border-[#EAECF0]">
              <Button
                onClick={() => {
                  setSelect('pts');
                  setAmountAdded({
                    ...amountAdded,
                    is_cashback: false,
                  });
                  if (amountAdded.amount > 0) {
                    setCheck(true);
                  }
                }}
                className={` w-[50%] font-semibold border-0 ${select !== 'pts' ? 'bg-[#F9FAFB] text-[#667085] ' : 'shadow-b-lg text-[#344054]'}`}
              >
                Pts
              </Button>
              <Button
                onClick={() => {
                  setSelect('cashback');
                  setAmountAdded({
                    ...amountAdded,
                    is_cashback: true,
                  });
                  if (amountAdded.amount > 0) {
                    setCheck(true);
                  }
                }}
                className={` w-[50%] font-semibold border-0 ${select !== 'cashback' ? 'bg-[#F9FAFB] text-[#667085] ' : 'shadow-b-lg text-[#344054]'}`}
              >
                Cashback
              </Button>
            </Form.Item>

            <h2 className="text-sm pb-2 pt-3">
              {select === 'pts' ? 'Points' : 'Cashback'} amount
            </h2>
            <div className="pb-8 z-10">
              <Input
                className="w-full   border-2 rounded-lg p-2"
                type="text"
                onChange={(e) =>
                  setAmountAdded({
                    ...amountAdded,
                    amount: Number(e.target.value),
                  })
                }
              />
              {error && <p className="text-red-500"> {error}</p>}
            </div>
            <div className="flex gap-3">
              <Button
                onClick={onClose}
                className="w-full font-semibold border-2 rounded-lg h-11 "
              >
                Back
              </Button>
              <Button
                className="w-full font-semibold border-2 rounded-lg h-11  text-white bg-[#040C82]"
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            </div>
          </Form>
        ) : (
          <div className="flex flex-col items-center">
            {check ? (
              <img src={successIcon} alt="" />
            ) : (
              <img src={failedIcon} alt="" />
            )}
            <p className="font-semibold py-4">
              {check
                ? select === 'pts'
                  ? `${amountAdded.amount} Points added`
                  : `EGP ${amountAdded.amount} added`
                : 'Failed'}
            </p>
            {check ? (
              <button
                className="w-full z-10 font-semibold border-2 rounded-lg h-11 p-2 text-white bg-[#040C82] "
                onClick={() => {
                  setConfirm(false);
                  setAmountAdded({
                    ...amountAdded,
                    amount: 0,
                  });
                }}
              >
                Confirm
              </button>
            ) : (
              <div className="flex w-full gap-3  z-10">
                <button
                  onClick={onClose}
                  className="w-full font-semibold border-2 rounded-lg p-2 h-11"
                >
                  Cancel
                </button>
                <button
                  className="w-full font-semibold border-2 rounded-lg p-2 text-white bg-[#040C82] h-11"
                  onClick={() => setConfirm(false)}
                >
                  Retry
                </button>
              </div>
            )}
          </div>
        )}
      </Modal>
    </>
  );
};
export default AwaitsCashBackPointsCustomerModal;
