import React, { useState } from 'react';
import './styles.css';
import colors from '../../Theme/colors';
import { Button, Card, Col, Form, Input, Row, Typography } from 'antd';
import { useNavigation } from '@refinedev/core';
import logo from '../../Assests/newLogo.svg';
import { ForgotPasswordAction } from '../../Actions/AuthActions';

const { Title } = Typography;
export const ForgotPassword: React.FC = () => {
  const [disabled, setDisabled] = useState(false);
  const { push } = useNavigation();
  const navigateToVerification = () => {
    push('/verification');
  };
  const CardTitle = (
    <div className="left-layout-title">
      {'Reset password'}
      <div className="left-layout-sub-title">
        Enter your email address for the verification process, and we will send
        you a five-digit code.
      </div>
    </div>
  );
  const handleDisableButton = () => {
    setDisabled((preValue) => !preValue);
  };

  return (
    <div
      style={{
        background: `#f5f5f5`,
        backgroundSize: 'cover',
      }}
    >
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div
            style={{
              maxWidth: '408px',
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <img
              style={{ marginBottom: 26, alignSelf: 'center' }}
              src={logo}
              width="370"
              alt="logo"
            />
            <Card
              title={CardTitle}
              headStyle={{ borderBottom: 0 }}
              style={{ backgroundColor: '#fff' }}
            >
              <Form
                layout="vertical"
                color={'#fff'}
                onFinish={(values) => {
                  ForgotPasswordAction(
                    values,
                    handleDisableButton,
                    navigateToVerification
                  );
                }}
                requiredMark={false}
              >
                <Form.Item
                  name="email"
                  label={'Email'}
                  rules={[{ required: true }]}
                  style={{ marginBottom: '3rem' }}
                >
                  <Input size="large" placeholder="Enter your email" />
                </Form.Item>
                <Button
                  type="default"
                  size="large"
                  htmlType="submit"
                  block
                  style={{
                    background: colors.authButtonColor,
                  }}
                  disabled={disabled}
                >
                  <p className="auth-button-text">{'Submit'}</p>
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};
