import React from 'react';
import { IResourceComponentsProps, useSelect } from '@refinedev/core';
import { Create, useForm } from '@refinedev/antd';
import {
  DatePicker,
  Form,
  Input,
  Select,
  Tooltip,
  Divider,
  InputNumber,
  Tabs,
  Space,
} from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import TabPane from 'antd/es/tabs/TabPane';
import dayjs from 'dayjs';

const { Option } = Select;

export const EarningMetricCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  const { queryResult } = useSelect({
    resource: 'product',
    filters: [
      {
        field: 'is_deleted',
        operator: 'eq',
        value: false,
      },
    ],
  });

  const transformedOptions =
    queryResult?.data?.data?.map((product) => ({
      value: product.id,
      label: `${product.type[0]?.type?.name || ''} - ${product.tier[0]?.tier?.name || ''} - ${product.segment[0]?.segment?.name || ''}`,
    })) ?? [];

  const validateEndDate = (
    _: any,
    value: string | number | Date | undefined
  ) => {
    const startDate = formProps.form?.getFieldValue([
      'earning_metric_input',
      'start_date',
    ]);
    if (value && dayjs(value).isBefore(dayjs(), 'day')) {
      return Promise.reject('End date cannot be in the past');
    }
    if (startDate && value && dayjs(value).isBefore(startDate)) {
      return Promise.reject('End date cannot be earlier than start date');
    }
    return Promise.resolve();
  };

  const validateStartDate = (
    _: any,
    value: string | number | Date | undefined
  ) => {
    const endDate = formProps.form?.getFieldValue([
      'earning_metric_input',
      'end_date',
    ]);
    if (value && endDate && dayjs(value).isAfter(endDate)) {
      return Promise.reject('Start date cannot be later than end date');
    }
    return Promise.resolve();
  };

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={
            <span>
              Name&nbsp;
              <Tooltip title="Enter a unique name for the earning metric. This will be used as a reference name.">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          name={['earning_metric_input', 'name']}
          rules={[
            {
              required: true,
              type: 'string',
              message: 'Please enter the name of the earning metric',
            },
            {
              max: 50,
              message: 'The name cannot be more than 50 characters long.',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Divider />

        <Form.Item
          initialValue="cashback"
          label={
            <span>
              Type&nbsp;
              <Tooltip title="Select the type of earning metric">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          name={['earning_metric_value_input', 'type']}
          rules={[
            {
              required: true,
              message: 'Please select the earning metric type',
            },
          ]}
        >
          <Tabs defaultActiveKey="cashback">
            <TabPane tab="Cashback" key="cashback"></TabPane>
            <TabPane tab="Points" key="points"></TabPane>
            {/* <TabPane
              tab="Points and Cashback"
              key="points_and_cashback"
            ></TabPane> */}
          </Tabs>
        </Form.Item>

        <Form.Item
          label={
            <span>
              PTS (per EGP)&nbsp;
              <Tooltip title="Enter the value for the earning metric per EGP">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          name={['earning_metric_value_input', 'value']}
          rules={[
            {
              required: true,
              message: 'Please enter the earning metric value',
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            placeholder="Enter the value for PTS per EGP"
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Expiry Duration (in years)&nbsp;
              <Tooltip title="Enter the duration in years for the earning metric to expire">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          name={['earning_metric_value_input', 'expiry_duration']}
          rules={[
            {
              required: true,
              message: 'Please enter the expiry duration',
            },
          ]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label={
            <span>
              MCC&nbsp;
              <Tooltip title="Enter the mcc associated with the earning metric">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          name={['earning_metric_value_input', 'mcc', 0]}
          rules={[
            {
              required: false,
              message: 'Please enter the merchant name',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Divider />

        <Form.Item
          label={
            <span>
              Product&nbsp;
              <Tooltip title="Select one or more products associated with the earning metric">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          name={['earning_metric_input', 'product_ids']}
          rules={[
            {
              required: true,
              message: 'Please enter the product details',
            },
          ]}
        >
          <Select mode="multiple" placeholder="Select products">
            {transformedOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Divider />

        <Form.Item
          label={
            <span>
              Start Date&nbsp;
              <Tooltip title="Select the start date for the earning metric">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          name={['earning_metric_input', 'start_date']}
          rules={[
            {
              required: false,
              message: 'Please enter the start date',
            },
            {
              validator: validateStartDate,
            },
          ]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label={
            <span>
              End Date&nbsp;
              <Tooltip title="Select the end date for the earning metric">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          name={['earning_metric_input', 'end_date']}
          rules={[
            {
              required: false,
              message: 'Please enter the end date',
            },
            {
              validator: validateEndDate,
            },
          ]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label="Status"
          name={['earning_metric_input', 'is_deleted']}
          initialValue={false}
          rules={[
            {
              required: false,
              message: 'Please select status',
            },
          ]}
        >
          <Select style={{ width: '200px' }}>
            <Select.Option value={false}>
              <Space>
                <CheckCircleOutlined style={{ color: 'green' }} />
                Active
              </Space>
            </Select.Option>
            <Select.Option value={true}>
              <Space>
                <CloseCircleOutlined style={{ color: 'red' }} />
                Inactive
              </Space>
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Create>
  );
};
