import React, { useEffect, useState } from 'react';
import logo from '../../Assests/newLogo.svg';
import { Typography, Layout, Row, Col, Card, Input, Button, Form } from 'antd';

import styled from 'styled-components';
import { ConfigsStore } from '../../Store';
import { VerificationAction } from '../../Actions/AuthActions';
import colors from '../../Theme/colors';
import { useNavigation } from '@refinedev/core';
export const Verification: React.FC = () => {
  const [disabled, setDisabled] = useState(false);

  const { push } = useNavigation();

  const navigateToResetPassword = () => {
    push('/reset-password');
  };
  const handleDisableButton = () => {
    setDisabled((preValue) => !preValue);
  };
  const CardTitle = (
    <div className="left-layout-title">
      Reset password
      <div className="left-layout-sub-title">
        We have just sent a verification code to example*****@mail.com. Check
        your email.
      </div>
    </div>
  );
  return (
    <Layout className="antdLayout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div
            style={{
              maxWidth: '408px',
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <img
              src={logo}
              width="370"
              style={{ marginBottom: 26, alignSelf: 'center' }}
              alt="logo"
            />
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form
                layout="vertical"
                color={colors.primaryLight}
                requiredMark={false}
                onFinish={(values) => {
                  VerificationAction(
                    { code: values?.code },
                    handleDisableButton,
                    navigateToResetPassword
                  );
                }}
              >
                <Form.Item
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the verification code',
                    },
                    {
                      pattern: /^[0-9]{5}$/,
                      message: 'Please enter a 5-digit verification code',
                    },
                  ]}
                >
                  <Input size="large" placeholder="●●●●●" />
                </Form.Item>
                <Button
                  type="default"
                  size="large"
                  htmlType="submit"
                  block
                  style={{
                    background: colors.authButtonColor,
                  }}
                  disabled={disabled}
                >
                  <p className="auth-button-text">{'Verify email'}</p>
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};
