import React from 'react';
import { useRouterContext, useRouterType, useLink } from '@refinedev/core';
import { Typography, theme, Space } from 'antd';
import type { RefineLayoutThemedTitleProps } from '../../types';
// import collapsedIcon from '../../Assests/collapsedIcon';
const { useToken } = theme;

const defaultIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} fill="none">
    <path
      fill="#F26242"
      d="M45 27.742 37.549 8.846l-14.904 8.33L15.872 0 0 8.87 14.248 45 45 27.742ZM41.91 3.679c.253 0 .447-.214.447-.48s-.194-.47-.448-.47h-.649v.95h.65ZM40.55 1.973h1.358c.627 0 1.12.542 1.12 1.226 0 .55-.307 1.004-.747 1.164l1 1.714h-.806l-.932-1.643h-.284v1.643h-.709V1.973Zm1.209 4.975c1.32 0 2.358-1.27 2.358-2.896 0-1.608-1.037-2.878-2.358-2.878-1.321 0-2.358 1.27-2.358 2.878 0 1.625 1.037 2.896 2.358 2.896Zm-3.104-2.896c0-2.061 1.365-3.678 3.104-3.678 1.738 0 3.104 1.617 3.104 3.678 0 2.07-1.365 3.695-3.104 3.695s-3.104-1.626-3.104-3.695Z"
    />
  </svg>
);

export const ThemedTitleV2: React.FC<RefineLayoutThemedTitleProps> = ({
  collapsed,
  text = '',
  icon = defaultIcon,
  wrapperStyles,
}) => {
  const { token } = useToken();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();

  const ActiveLink = routerType === 'legacy' ? LegacyLink : Link;

  return (
    <ActiveLink
      to="/"
      style={{
        display: 'inline-block',
        textDecoration: 'none',
      }}
    >
      <Space
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: 'inherit',
          ...wrapperStyles,
        }}
      >
        <div
          style={{
            color: token.colorPrimary,
          }}
        >
          {icon}
        </div>

        {!collapsed && (
          <Typography.Title
            style={{
              fontSize: 'inherit',
              marginBottom: 0,
              fontWeight: 700,
            }}
          >
            {text}
          </Typography.Title>
        )}
      </Space>
    </ActiveLink>
  );
};
